var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("div", { staticClass: "fz-20 fz-w" }, [
            _vm._v("根据地区分布，销售额统计")
          ]),
          _c("c-tab", {
            attrs: { options: _vm.timeStatus, value: _vm.query.timeStatus }
          })
        ],
        1
      ),
      _c("echarts", { attrs: { height: "300px", options: _vm.options } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }